<template>
<div>

  <v-card>
    <v-card-title>
      {{titulo}}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="fas fa-search"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-container>
          <v-row>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="12" md="3">
            <v-btn
              color="purple darken-3"
              class="ma-2 white--text"
              @click="showModal"
              block
            >
              Cargar Molida <v-icon right >
                fas fa-upload
              </v-icon>
            </v-btn>
          </v-col>

          </v-row>
    </v-container>

      <!-- :items="currentItems" -->
    <v-data-table
      v-model="editedItem.selected"
      :headers="headers"
      :items="currentItems"
      :search="search"
      sort-by="id"
      sort-desc
      class="elevation-1"
      :loading="loading"
      loading-text="Consultando datos... Espere por favor"
      show-select
      checkbox-color="success"
      item-key="coddoc"
      
    >
      <template v-slot:top>
          <v-dialog v-model="dialog" max-width="1200px">
            <!-- <template v-slot:activator="{ on }">
                <v-btn ref="boton2" color="primary" dark v-on="on">Nuevo Registro</v-btn>
            </template> -->
            <v-card>
              <v-card-title>
                <span class="headline">Carga de Molida</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form ref="form" v-model="valid" autocomplete="off">
                    <v-row>
                      <!-- <v-col cols="12" sm="12" md="6">
                        <v-autocomplete
                          v-model="editedItem.id_dryer"
                          :items="dryers"
                          item-text="name"
                          item-value="id"
                          label="Escoge una Secadora"
                          :rules="editedItem.id_dryerRules"
                          required
                          clearable
                          rounded
                          solo
                        ></v-autocomplete>
                      </v-col> -->

                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                            v-model="editedItem.comment"
                            label="Observación"
                            required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>

                  <v-data-table
                    v-model="editedItem.selected"
                    :headers="headers_modal"
                    :items="editedItem.selected"
                    dense
                    sort-by="id"
                    sort-desc
                    class="elevation-1"
                    :loading="loading"
                    loading-text="Consultando datos... Espere por favor"
                    show-select
                    item-key="coddoc"
                    checkbox-color="error"
                  >

                  <template slot="body.append">
                    <tr class="gray--text">
                      <!-- <th class="title"></th> -->
                      
                      <th colspan="8" class="title">
                      <v-row class="pt-2">
                        <v-col cols="12" sm="12" md="4">
                          Total: {{ numberWithCommas((parseFloat(sumUva('cant'))).toFixed(2)) }}
                        </v-col>
                      </v-row>
                      </th>
                      
                    </tr>
                  </template>

                  </v-data-table>

                </v-container>
              </v-card-text>

              <!-- <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
                <v-btn color="blue darken-1" text @click="save">Guardar</v-btn>
              </v-card-actions> -->

              <v-card-actions>
                <v-row align="center" class="d-flex mb-2 mx-4">
                  <v-col cols="12" sm="12" md="6">

                  </v-col>
                  <v-col cols="12" sm="12" md="3">

                    <v-btn
                      color="warning"
                      rounded
                      outlined
                      class="flex-grow-1"
                      block
                      large
                      @click="dialog = false"
                      :elevation="0"
                      :minHeight="56"
                      >Cancelar
                    </v-btn>

                  </v-col>

                  <v-col  cols="12" sm="12" md="3">

                    <v-btn
                      color="primary"
                      rounded
                      block
                      large
                      @click="save"
                      :elevation="0"
                      :minHeight="56"
                      >Guardar
                    </v-btn>

                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-dialog>
          
      </template>

      <template slot="body.append">
      <tr class="gray--text">
        <!-- <th class="title"></th> -->
        
        <th colspan="8" class="title">
        <v-row class="pt-2">
          <v-col cols="12" sm="12" md="4">
            Total: {{ numberWithCommas((parseFloat(sumField('cant'))).toFixed(2)) }}
          </v-col>
        </v-row>
        </th>
        
      </tr>
    </template>
    </v-data-table>
  </v-card>
</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
import * as moment from 'moment';

export default {
  name: "weight_notes",
  data: () => ({
    dialog: false,
    samplings: [],
    currentItems: [],
    items: [],
    dryers: [],
    types: [],
    damages: [],
    processes: [],
    damages: [],
    process: "",
    type: "",
    id_dryer: null,
    dates: [],
    modal: false,
    loader: null,
    loading3: false,
    dialogDelete: false,
    search: "",
    loading: false,
    titulo: "Notas de Peso",
    headers: [
      { text: "Fecha", align: "left", value: "fecha", sortable: false},
      { text: "Nombre", align: "left", value: "nomprov" },
      { text: "#Factura", value: "bill" },
      { text: "Nota de Peso", value: "coddoc" },
      { text: "Clase de Café", value: "product" },
      { text: "Cantidad", value: "cant" },
      // { text: "Precio", value: "precio" },
    ],
    headers_modal: [
      { text: "Fecha", align: "left", value: "fecha", sortable: false},
      { text: "Nombre", align: "left", value: "nomprov" },
      { text: "Nota de Peso", value: "coddoc" },
      { text: "Clase de Café", value: "product" },
      { text: "Cantidad", value: "cant" },
      // { text: "Precio", value: "precio" },
    ],
    editedIndex: -1,

    valid: true,
    

    editedItem: {
      id_dryer: null,
      comment: "",
      selected: [],
      total: "",
      id_dryedRules: [v => !!v || "Escoge una Secadora"],
    },
    defaultItem: {
      id_dryer: null,
      comment: "",
      selected: [],
      total: "",
      id_dryedRules: [v => !!v || "Escoge una Secadora"],
    },
  }),

  computed: {
    
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Registro" : "Editar Registro";
    },
    ...mapState(["db","headers_db"]),
    dateRangeText: {
        get()
        {
            return this.dates.join(' ~ ')
        },
        set()
        {
            this.dates = []
        }
    },
  },

  watch: {
    // dialog(val) {
    //   val || this.close();
    // },
    loader () {
        const l = this.loader
        this[l] = !this[l]

        setTimeout(() => (this[l] = false), 1000)

        this.loader = null
    },
  },

  methods: {
    ...mapActions(["validateSession"]),
    // Metodos base de datos
    async getSamplings(){
      await axios.get(this.db + 'notes',
        {
          headers: this.headers_db
        }).then(response => {
          this.samplings = this.currentItems = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getSamplings(); })
        });
    },

    async getDryers(){
      await axios.get(this.db + 'dryers',
        {
          headers: this.headers_db
        }).then(response => {
          this.dryers = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getDryers(); })
        });
    },
    // FIN Metodos base de datos

    // Metodos Generales
    dateFormat(date) {
      return moment(date).format('DD/MM/YYYY hh:mm a');
    },
    
    sumField(key) {
      return this.currentItems.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
    },

    sumUva(key) {
      return this.editedItem.selected.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
    },

    showModal() {
      this.dialog = true;
      this.editedItem.total = this.editedItem.selected.reduce((a, b) => parseFloat(a) + (parseFloat(b['cant']) || 0), 0);
    },
    getByDryer() {
      console.log(this.id_dryer, this.samplings);
      if (this.id_dryer) {
        this.currentItems = this.samplings.filter(doc => {
          return doc.codprov == this.id_dryer;
        });
      }
      else {
        this.currentItems = this.samplings;
      }
    },

    close() {
      this.dialog = false;
      this.dialogFactor = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      }, 300);
    },
    save() {
      
      if (this.$refs.form.validate()) {
        let selected = this.editedItem.selected;

        axios.post(this.db + 'grind/add', {
          comment: this.editedItem.comment,
          quantity: this.editedItem.total,
          ids_cxp: selected
        },
        {
          headers: this.headers_db
        })
        .then((response) => {
          console.log(response.data.message);
          this.$store.dispatch("setSnackbar", {
            text: response.data.message,
          });
          // this.getSamplings();
          selected.forEach(element => {
            this.samplings = this.samplings.filter(doc => {
              return doc.id_cxp != element.id_cxp
            })
          });
          this.currentItems = this.samplings;
          this.close();
        })
        .catch(function (error) {
          console.log(error);
          this.$store.dispatch("setSnackbar", {
            text: error,
          });
        });
      }
    },

    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

  async getAll() {
      this.loader = 'loading3'
      this.loading = true;
      await this.getSamplings();
      this.getDryers();
    },
  },
  async mounted() {
    this.loading = true;
    await this.getSamplings();
    this.getDryers();
  },
  filters: {
    formatNumber: function(value) {
      if (!value) return "";
      return new Intl.NumberFormat().format(value);
    },
  },
};
</script>

<style lang="scss">
  .totales {
    font-size: medium !important;
  }
</style>
